@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"
















































































































































.exams-navigation-list
  display: flex
  gap: 16px
  margin-bottom: 24px

.partial-result
  position: relative
  padding: 48px 0px
  box-sizing: border-box

  +mq-l
    position: unset

  & > .wrapper

    +mq-xs
      max-width: 100% !important

  &__banner

    +mq-xs
      padding: 0 16px

  &__hits
    margin-top: 40px

    +mq-xs
      padding: 0 16px

  &__questions
    margin-top: 40px

    &-header
      margin-bottom: 24px

  .info-block__content

    p
      line-height: inherit
